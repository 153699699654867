import { Controller } from '@hotwired/stimulus';
// Connects to data-controller="scenarios"
export default class extends Controller {
  static targets = ['select', 'optimizerModal', 'additionalLearners'];

  connect() {
    $(this.selectTargets).on('select2:select select2:unselect', (event) => {
      event.target.form.requestSubmit();
    });

    $(this.selectTargets).on('select2:clear', (event) => {
      $(event.currentTarget).val([]).trigger('change');
      event.currentTarget.form.requestSubmit();
    });

    this.addModalEvents();
  }

  addModalEvents() {
    this.optimizerModalTarget.addEventListener('hide.bs.modal', () => {
      const element = this.optimizerModalTarget.querySelector('#optimizer-modal-content');
      element?.replaceChildren();
    });
  }

  acceptOptimizerResult(event) {
    const candidateId = event.currentTarget.getAttribute('candidate_id');
    const additionalLearners = $(this.additionalLearnersTarget);
    additionalLearners.val([...additionalLearners.val(), candidateId]).trigger('change');
    this.additionalLearnersTarget.form.requestSubmit();
  }
}
