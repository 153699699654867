import * as bootstrap from 'bootstrap';

document.addEventListener('turbo:load', () => {
  // Set the active tab on arrival if the tab is linked to from another page
  if ($('.nav-tabs').length && document.location.hash) {
    const triggerElement = document.querySelector(`.nav-tabs a[href='${document.location.hash}']`);
    bootstrap.Tab.getOrCreateInstance(triggerElement).show();
  }

  // Update the url when the tab changes
  $('.nav-tabs a').on('shown.bs.tab', (event) => {
    window.history.pushState(null, null, event.target.hash);
  });
});
