import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="range-slider"
export default class extends Controller {
  static targets = ['rating'];

  connect() {
    this.ratingTarget.addEventListener('input', (event) => {
      const { parentNode } = event.target;
      const rating = event.target.value;
      parentNode.style.setProperty('--value', rating);
      parentNode.style.setProperty('--text-value', JSON.stringify(rating));
    });
  }
}
