import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

// Connects to data-controller="team-selection"
export default class extends Controller {
  connect() {
    $(this.element).on('select2:select', (event) => {
      const selectedValue = event.target.value;
      const url = new URL(window.location);
      url.searchParams.set('selected_team', selectedValue);
      window.history.pushState({}, '', url);
      Turbo.visit(url);
    });
  }
}
