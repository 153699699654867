const initializeNestedCheckboxes = () => {
  const updateNestedCheckboxes = (parentCheckbox) => {
    const section = $(parentCheckbox).parents('.js-checkbox-group');
    const checkboxes = section.find('.js-nested-checkbox');
    checkboxes.prop('checked', $(parentCheckbox).is(':checked'));
  };

  const updateParentCheckbox = (section) => {
    const sectionCount = section.find('.js-nested-checkbox').length;
    const checkedCount = section.find('.js-nested-checkbox:checked').length;
    const parentCheckbox = section.find('.js-parent-checkbox');
    parentCheckbox.prop('indeterminate', false); // reset to unchecked

    if (checkedCount > 0) {
      if (checkedCount === sectionCount) {
        parentCheckbox.prop('checked', true);
      } else {
        parentCheckbox.prop('indeterminate', true);
      }
    } else {
      parentCheckbox.prop('checked', false);
    }
  };

  // Set up parent checkbox status on page load
  $('.js-checkbox-group').each(function setUpParentCheckboxStatus() {
    updateParentCheckbox($(this));
  });

  $('.js-parent-checkbox').on('click', (event) => updateNestedCheckboxes(event.target));

  $('.js-nested-checkbox').on('click', (event) => {
    const section = $(event.target).parents('.js-checkbox-group');
    updateParentCheckbox(section);
  });
};

document.addEventListener('turbo:load', () => {
  initializeNestedCheckboxes();
});

document.addEventListener('turbo:render', (event) => {
  if (!event.detail.isPreview) {
    initializeNestedCheckboxes();
  }
});
