document.addEventListener('turbo:load', () => {
  // Check all checkboxes in the section
  $('.js-show-all').on('click', (event) => {
    const section = $(event.target).parents('.js-checkboxes-section');
    section.find('input[type=checkbox]').prop('checked', true);
  });

  // Uncheck all checkboxes in the section
  $('.js-clear-all').on('click', (event) => {
    const section = $(event.target).parents('.js-checkboxes-section');
    section.find('input[type=checkbox]').prop('checked', false);
  });

  $('.js-custom-radio input').on('change', (event) => {
    const wrapper = $(event.target).closest('.js-custom-radio');
    wrapper.siblings().removeClass('custom-radio-selected');
    wrapper.addClass('custom-radio-selected');
  });
});
