import { Controller } from '@hotwired/stimulus';
import select2 from 'select2';

select2($);
$.fn.select2.defaults.set('theme', 'bootstrap-5');

// Connects to data-controller="select2"
export default class extends Controller {
  static values = {
    clearable: { type: Boolean, default: true }
  };

  connect() {
    // Call this generic select2 instantiation before others with more detailed parameters
    //
    // Unselecting hack is so the drop down doesn't remain open after clearing the input
    // https://stackoverflow.com/questions/29618382/disable-dropdown-opening-on-select2-clear
    this.selectInput = $(this.element).select2({
      allowClear: this.clearableValue,
      width: 'style'
    }).on('select2:unselecting', function setUnselecting() {
      $(this).data('unselecting', true);
    }).on('select2:opening', function preventOpenWhenUnselecting(event) {
      if ($(this).data('unselecting')) {
        $(this).removeData('unselecting');
        event.preventDefault();
      }
    });

    $('#jump_to_matrix_item').select2({
      allowClear: false,
      width: '100%'
    });

    $('#jump_to_matrix_item').on('select2:select', (event) => {
      const path = event.target.value;
      window.location = path;
    });

    // Submit the audience filter form when the selection is changed
    $('.js-audience-filter').on('change', (event) => {
      event.target.form.submit();
    });

    // N.B. Destroy the select2 functionality before Turbo caches the page so that when
    // clicking the "back" button, the select2 functionality isn't initialized more than once
    this.beforeCacheHandler = this.destroySelect2.bind(this);
    document.addEventListener('turbo:before-cache', this.beforeCacheHandler);
  }

  disconnect() {
    document.removeEventListener('turbo:before-cache', this.beforeCacheHandler);
  }

  destroySelect2() {
    this.selectInput?.select2('destroy');
  }
}
