import consumer from '@/channels/consumer';

consumer.subscriptions.create('TeamLearningMomentsChannel', {
  collection() {
    return $("[data-channel='team-learning-moments']");
  },

  received(data) {
    if (!this.userIsCurrentUser(data.message)) { this.collection().prepend(data.message); }
    $('.js-no-learning-moments').hide();
  },

  userIsCurrentUser(message) {
    return $(message).attr('data-user-id') === $('meta[name=current-user]').attr('id');
  }
});
