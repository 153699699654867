import consumer from '@/channels/consumer';

consumer.subscriptions.create('OrganizationLearningMomentsChannel', {
  collection() {
    return $("[data-channel='organization-learning-moments']");
  },

  received(data) {
    if (!this.userIsCurrentUser(data.message)) { this.collection().prepend(data.message); }
    $('.js-no-learning-moments').hide();

    const authorReference = `${data.team_name} Team Member`;
    const learningMoment = $(`[data-learning-moment-id='${data.id}']`);
    learningMoment.find('.js-learning-moment-author').replaceWith(authorReference);
  },

  userIsCurrentUser(message) {
    return $(message).attr('data-user-id') === $('meta[name=current-user]').attr('id');
  }
});
